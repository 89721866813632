var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "multiselect",
    {
      attrs: {
        openDirection: "bottom",
        placeholder: "Search by code or description",
        label: "code",
        "custom-label": _vm.codeWithDescription,
        "track-by": "code",
        options: _vm.selectableCodes,
        multiple: _vm.multiple,
        loading: _vm.codesLoading,
        "close-on-select": false,
        "internal-search": false,
        "show-labels": false,
        showNoOptions: true,
      },
      on: { "search-change": _vm.debounceCPTCodes },
      model: {
        value: _vm.selectedCode,
        callback: function ($$v) {
          _vm.selectedCode = $$v
        },
        expression: "selectedCode",
      },
    },
    [
      _c("template", { slot: "noResult" }, [
        _vm._v(" No codes matched your search "),
      ]),
      _c("template", { slot: "noOptions" }, [
        _vm._v(" Start typing a code or description... "),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }