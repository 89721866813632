var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "multiselect",
    {
      attrs: {
        openDirection: "bottom",
        placeholder: "Search by provider name or address",
        "custom-label": _vm.providerLabel,
        "track-by": "provider_id",
        options: _vm.selectableProviders,
        loading: _vm.loading || _vm.providersLoading,
        "internal-search": false,
        "close-on-select": _vm.closeOnSelect,
        multiple: _vm.multiple,
        "show-labels": false,
        showNoOptions: true,
      },
      on: { "search-change": _vm.debounceProviders },
      model: {
        value: _vm.selectedProvider,
        callback: function ($$v) {
          _vm.selectedProvider = $$v
        },
        expression: "selectedProvider",
      },
    },
    [
      _c("template", { slot: "noResult" }, [
        _vm._v(" No providers matched your search "),
      ]),
      _c("template", { slot: "noOptions" }, [
        _vm._v(" Start typing a provider name or address... "),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }