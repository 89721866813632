var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "invite-user" } },
    [
      _c("avo-alert", {
        attrs: { avoAlerts: _vm.avoAlerts },
        on: {
          "update:avoAlerts": function ($event) {
            _vm.avoAlerts = $event
          },
          "update:avo-alerts": function ($event) {
            _vm.avoAlerts = $event
          },
        },
      }),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _c(
                "b-form",
                {
                  ref: "addUserForm",
                  on: { submit: _vm.onSubmit, reset: _vm.onReset },
                },
                [
                  _c(
                    "b-card",
                    { staticClass: "mt-2 mb-2" },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            "label-cols-lg": "3",
                            label: "User details",
                            "label-size": "lg",
                          },
                        },
                        [
                          _c(
                            "b-form-row",
                            [
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        id: "user-invite-given-name-label",
                                        label: "First name",
                                        "label-for": "user-invite-given-name",
                                      },
                                    },
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          id: "user-invite-given-name",
                                          type: "text",
                                          placeholder: "Given name of new user",
                                          required: "",
                                        },
                                        model: {
                                          value: _vm.form.givenName,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.form, "givenName", $$v)
                                          },
                                          expression: "form.givenName",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        id: "user-invite-family-name-label",
                                        label: "Last name",
                                        "label-for": "user-invite-family-name",
                                      },
                                    },
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          id: "user-invite-family-name",
                                          type: "text",
                                          placeholder:
                                            "Family name of new user",
                                          required: "",
                                        },
                                        model: {
                                          value: _vm.form.familyName,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "familyName",
                                              $$v
                                            )
                                          },
                                          expression: "form.familyName",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                id: "user-invite-email-label",
                                label: "Email address",
                              },
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "user-invite-email",
                                  type: "email",
                                  placeholder:
                                    "Type the email address of the new user",
                                  required: "",
                                },
                                model: {
                                  value: _vm.form.email,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "email", $$v)
                                  },
                                  expression: "form.email",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-card",
                    { staticClass: "mb-2" },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            "label-cols-lg": "3",
                            label: "Permissions",
                            "label-size": "lg",
                          },
                        },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: { id: "user-invite-role", label: "Role" },
                            },
                            [
                              _c("multiselect", {
                                attrs: {
                                  options: _vm.rolesOptions,
                                  "show-labels": false,
                                  "hide-selected": true,
                                  "close-on-select": false,
                                  "track-by": "text",
                                  label: "text",
                                  multiple: true,
                                  placeholder: "-- Select Role(s) --",
                                  required: "",
                                },
                                model: {
                                  value: _vm.form.roles,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "roles", $$v)
                                  },
                                  expression: "form.roles",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.doCreateProvider
                    ? _c("b-card", { staticClass: "mb-2" }, [
                        _c(
                          "div",
                          { staticClass: "d-flex " },
                          [
                            _c(
                              "b-form-group",
                              {
                                attrs: {
                                  "label-cols-lg": "3",
                                  "label-size": "lg",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "label",
                                      fn: function () {
                                        return [
                                          _vm._v(" Provider "),
                                          _c(
                                            "b-form-text",
                                            { staticClass: "mt-2 mr-3" },
                                            [
                                              _vm._v(
                                                " Please select the provider to be associated with this user account "
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  3051047687
                                ),
                              },
                              [
                                _c(
                                  "b-form-group",
                                  { attrs: { label: "Select a Provider" } },
                                  [
                                    _c("provider-multiselect", {
                                      ref: "providerMultiselectInstance",
                                      attrs: {
                                        multiple: false,
                                        "close-on-select": true,
                                      },
                                      model: {
                                        value: _vm.selectedProvider,
                                        callback: function ($$v) {
                                          _vm.selectedProvider = $$v
                                        },
                                        expression: "selectedProvider",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-button",
                              {
                                directives: [
                                  {
                                    name: "b-modal",
                                    rawName: "v-b-modal.new-provider",
                                    modifiers: { "new-provider": true },
                                  },
                                ],
                                staticClass: "avo-primary-btn",
                                staticStyle: {
                                  "margin-top": "32px",
                                  "margin-left": "12px",
                                },
                              },
                              [_vm._v(" New Provider ")]
                            ),
                            _c(
                              "b-modal",
                              {
                                attrs: {
                                  id: "new-provider",
                                  title: "Create new provider",
                                  "hide-footer": "",
                                  size: "lg",
                                },
                              },
                              [
                                _c("provider-add", {
                                  on: {
                                    submittedProvider: function ($event) {
                                      return _vm.newProviderModalSubmittedCallback(
                                        $event
                                      )
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm.doAssociatePractice
                    ? _c("b-card", { staticClass: "mb-2" }, [
                        _c(
                          "div",
                          { staticClass: "d-flex " },
                          [
                            _c(
                              "b-form-group",
                              {
                                attrs: {
                                  "label-cols-lg": "3",
                                  "label-size": "lg",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "label",
                                      fn: function () {
                                        return [
                                          _vm._v(" Practice "),
                                          _c(
                                            "b-form-text",
                                            { staticClass: "mt-2 mr-3" },
                                            [
                                              _vm._v(
                                                " Please select the practice to be associated with this administrative account "
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  3274442538
                                ),
                              },
                              [
                                _c(
                                  "b-form-group",
                                  { attrs: { label: "Select a Practice" } },
                                  [
                                    _c("practice-multiselect", {
                                      model: {
                                        value: _vm.selectedPractice,
                                        callback: function ($$v) {
                                          _vm.selectedPractice = $$v
                                        },
                                        expression: "selectedPractice",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm.doCreateWorker
                    ? _c(
                        "b-card",
                        { staticClass: "mb-2" },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                "label-cols-lg": "3",
                                "label-size": "lg",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "label",
                                    fn: function () {
                                      return [
                                        _vm._v(" Assignments "),
                                        _c(
                                          "b-form-text",
                                          { staticClass: "mt-2 mr-3" },
                                          [
                                            _vm._v(
                                              " Please select the practices, payers, and CPT codes in which this user will specialize "
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                2434185229
                              ),
                            },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    id: "user-invite-practices",
                                    label: "Practices",
                                  },
                                },
                                [
                                  _c("practice-multiselect", {
                                    attrs: { multiple: true },
                                    model: {
                                      value: _vm.form.practices,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "practices", $$v)
                                      },
                                      expression: "form.practices",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    id: "user-invite-payers",
                                    label: "Payers",
                                  },
                                },
                                [
                                  _c(
                                    "b-col",
                                    [
                                      _c("payer-multiselect", {
                                        attrs: { multiple: true },
                                        model: {
                                          value: _vm.form.payers,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.form, "payers", $$v)
                                          },
                                          expression: "form.payers",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm.payersGroupsOptions &&
                                  _vm.payersGroupsOptions.length > 0
                                    ? _c(
                                        "b-col",
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Add by Payer Group:",
                                              },
                                            },
                                            [
                                              _c("multiselect", {
                                                attrs: {
                                                  "show-labels": false,
                                                  value:
                                                    _vm.selectedPayerGroups,
                                                  options:
                                                    _vm.payersGroupsOptions,
                                                  "close-on-select": false,
                                                  taggable: true,
                                                  multiple: true,
                                                  "track-by": "value",
                                                  label: "text",
                                                  disabled: _vm.optionsLoading,
                                                  placeholder:
                                                    "-- Select Payer Group --",
                                                },
                                                on: {
                                                  select: _vm.addPayersByGroup,
                                                  remove: _vm.popPayerGroup,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    id: "user-invite-codes",
                                    label: "CPT Codes",
                                  },
                                },
                                [
                                  _c("code-multiselect", {
                                    attrs: { multiple: true },
                                    model: {
                                      value: _vm.form.codes,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "codes", $$v)
                                      },
                                      expression: "form.codes",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "d-flex justify-content-end pb-5 pt-2" },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "avo-basic-btn ml-2",
                          attrs: { type: "reset", variant: "danger" },
                        },
                        [_vm._v("Clear Fields")]
                      ),
                      _c(
                        "b-button",
                        {
                          staticClass: "avo-primary-btn ml-2",
                          attrs: { type: "submit", variant: "primary" },
                        },
                        [_vm._v("Add New User")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }