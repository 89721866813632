var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.internalAvoAlerts, function (alert, index) {
      return _c(
        "b-alert",
        {
          key: "alert" + index,
          staticClass: "avo-text-bold",
          attrs: {
            show: alert.show,
            variant: alert.variant,
            dismissible: alert.dismissible,
          },
          on: {
            dismissed: function ($event) {
              return _vm.dismissAlert(index)
            },
          },
        },
        [_vm._v(" " + _vm._s(alert.message) + " ")]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }